import i18n from '../i18n';
import { _dataProvider } from './DataProvider';
//import { getBibleRef, initializeAsNeeded } from './AppBible/bible.js';
import BooksInBible from './AppBible/bible.json';

interface IBibleVerse {
  verse: string;
  text: string;
}

interface IBiblePassage {
  verses: IBibleVerse[];
  chapterFrom: number;
  verseFrom: number;
  chapterTo: number;
  verseTo: number;
}

export class BibleData {
  langIndex = ['chs', 'cht', 'eng'];
  bibleIds = ['cuvs', 'cuvt', 'asv'];
  booksOfBible = new Array(66).fill([]);
  bookChapters = new Array(66).fill(0);

  constructor() {
    //const bookNames = {};
    //initializeAsNeeded(bookList);

    let index = 0;
    for (const book of BooksInBible) {
      const bookData = book.split('|');
      this.booksOfBible[index] = bookData;
      this.bookChapters[index] = parseInt(bookData[bookData.length - 1]);
      index++;
    }
    console.log('bibledata: 1', this.booksOfBible);
  }

  //TODONOW:
  // reserve a special lessonId (maybe 0???)
  //  dataprovider just call BibleData to get it...
  //  check with the App that has an Bible for XiJue bible, not from the server.
  //
  //public async getBookTable(lessonId: string): Promise<IBookTable> {
  //  const url = `${this.serverUrl}/lesson/${encodeURIComponent(lessonId)}`;

  public getBookTable() {
    const idx = this.langIndex.indexOf(i18n.language);
    console.log('BibleData: getBookTable: ', this.booksOfBible, idx, i18n.language);
    const sessions = this.booksOfBible.map((bookName: string[], index: number) => {
      return {
        lesson: `${index}.json`,
        title: bookName[idx],
        lessonId: '0'
      };
    });

    //TODONOW:
    // what about other things like className, and class description??
    return { title: '圣经 Bible', sessions, lessonId: '0' };
  }
  //
  //public async getContent(lessonId: string, filename: string): Promise<IBookTable | IBookContent> {
  //
  public async getBookContent(filename: string) {
    const idx = this.langIndex.indexOf(i18n.language);
    console.log('BibleData: getBookContent: ', idx, i18n.language);
    const bibleWhole = await _dataProvider.getBible(this.bibleIds[idx]);

    const bookId = parseInt(filename);
    console.log('loading bible: ', bookId);

    /* IBookContent
      title: string; //this is lesson title
      version: number;
      sessions: IBookContentSession[];
      lessonId?: string;
      chapterId: string;
    */
    const bookContent = {
      title: this.booksOfBible[bookId][idx],
      chapterId: bookId.toString(),
      sessions: [] as any[],
      version: 0,
      lessonId: '0'
    };

    for (let chapterIdx = 1; chapterIdx <= this.bookChapters[bookId]; chapterIdx++) {
      const biblePassage = this.getPassage(bibleWhole, bookId + 1, chapterIdx.toString());
      const chapterContent = { content: [] as any[] };
      chapterContent.content = biblePassage.verses.map((vvv) => {
        return {
          type: 'text',
          value: vvv.verse + ': ' + vvv.text
        };
      });
      bookContent.sessions.push(chapterContent);
    }
    return bookContent;
    //TODONOW:
    //generate the whole book with all chapters, for now
    //    later, we generate per chapter on demand.
    //
    //
    //TODONOW
    //The App loads one chapter at a time.
    //We probably should go with that model, and merge the template.html with App
    //we then don't need to generate the json for the whole of Genesis,
    //just given one chapter at a time.
    //and the text can come of the bible.js' getpassage().  we'd get all the text
  }

  getPassage = (bible: any, bookId: number, verseReference: string): IBiblePassage => {
    const biblePassage: IBiblePassage = {
      verses: [] as IBibleVerse[],
      chapterFrom: 0,
      verseFrom: 0,
      chapterTo: 0,
      verseTo: 0
    };
    if (!bible) {
      return biblePassage;
    }

    const items = verseReference.split(/(:|-)/g);
    if (items.length === 1) {
      // parse chapter: 1
      biblePassage.chapterFrom = parseInt(items[0]);
      biblePassage.verseFrom = 1;
      biblePassage.chapterTo = biblePassage.chapterFrom;
      biblePassage.verseTo = 999;
    } else if (items.length === 3 && items[1] === '-') {
      // parse chapter: 1-2
      biblePassage.chapterFrom = parseInt(items[0]);
      biblePassage.verseFrom = 1;
      biblePassage.chapterTo = parseInt(items[2]);
      biblePassage.verseTo = 999;
    } else if (items.length === 3 && items[1] === ':') {
      // parse chapter: 1:33
      biblePassage.chapterFrom = parseInt(items[0]);
      biblePassage.verseFrom = parseInt(items[2]);
      biblePassage.chapterTo = biblePassage.chapterFrom;
      biblePassage.verseTo = biblePassage.verseFrom;
    } else if (items.length === 5 && items[1] === ':' && items[3] === '-') {
      // parse chapter: 1:1-3
      biblePassage.chapterFrom = parseInt(items[0]);
      biblePassage.verseFrom = parseInt(items[2]);
      biblePassage.chapterTo = biblePassage.chapterFrom;
      biblePassage.verseTo = parseInt(items[4]);
    } else if (items.length === 7 && items[1] === ':' && items[3] === '-' && items[5] === ':') {
      // parse chapter: 1:1-2:10
      biblePassage.chapterFrom = parseInt(items[0]);
      biblePassage.verseFrom = parseInt(items[2]);
      biblePassage.chapterTo = parseInt(items[4]);
      biblePassage.verseTo = parseInt(items[6]);
    } else {
      alert('Error format: ' + verseReference);
      return biblePassage;
    }

    let chapter = biblePassage.chapterFrom;
    let verseIdx = biblePassage.verseFrom;
    while (chapter * 1000 + verseIdx <= biblePassage.chapterTo * 1000 + biblePassage.verseTo) {
      const id = bookId * 1000000 + chapter * 1000 + verseIdx;
      // Chinese bible has some empty verse
      if (!bible[id] && !bible[id + 1]) {
        chapter++;
        verseIdx = 1;
      } else {
        const text = bible[id] ? bible[id] : '';
        biblePassage.verses.push({
          verse: `${chapter}:${verseIdx}`,
          text: this.getVerseText(text)
        });
        verseIdx++;
      }
    }
    if (biblePassage.verseTo === 999) {
      biblePassage.verseTo = verseIdx - 1; // in case it was set to 999
    }
    return biblePassage;
  };

  getVerseText = (verseText: string) => {
    const AnnotationWords = ['the', 'in', 'of', 'on', 'and', 'an', 'to', 'a', 'as', 'for'];
    // Check to see if the first line is part of the bible
    const firstLinePos = verseText.indexOf('\n');
    if (firstLinePos !== -1) {
      const firstLine = verseText.substring(0, firstLinePos);
      let annotation = true;
      if (verseText.length > firstLinePos) {
        // We have more than one lines
        const words = firstLine.split(' ');
        // It has to be more than one words
        if (words.length > 1) {
          // Check each word starts with upper case
          for (const w in words) {
            if (AnnotationWords.indexOf(words[w]) === -1 && words[w][0] !== words[w][0].toUpperCase()) {
              // Not upper case, not an annotation
              annotation = false;
              break;
            }
          }

          // Use "()" for annotation if found
          if (annotation) {
            verseText = '[' + firstLine + '] ' + verseText.substring(firstLinePos + 1);
          }
        }
      }
    }

    return verseText;
  };
}

export const _bibleData = new BibleData();

/*
  const bookList = BooksInBible

      let bookNames = {};
      let longNames = [];

      initializeAsNeeded(bookList);
      for (let index = 0; index < bookList.length; index++) {
          const bookData = bookList[index].split('|');
          for (let i = 0; i < bookData.length - 1; i++) {
              bookNames[bookData[i]] = index+1;
          }
          booksOfBible[0][index] = bookData[0];
          booksOfBible[1][index] = bookData[1];
          booksOfBible[2][index] = bookData[2];
      }

	    window.getBibleRef = getBibleRef;
        
      function longBookName(name) {
          const bookId = bookNames[name];
          let book = longNames[bookId-1];
          return {bookId, book};
      }
	    window.longBookName = longBookName;

    </script>
*/
