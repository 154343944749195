import { IBookContent, IContent, IReferenceValue, IBookContentSession } from '../dataProvider/IBookContent';
import { _dataProvider } from '../dataProvider/DataProvider';
import iconAddText from '../img/icon-TXT.png';
import iconAnswerBox from '../img/icon-AnswerBox.png';
import iconMediaFile from '../img/icon-MediaFile.png';
import iconFile from '../img/icon-file.png';
import iconScript from '../img/icon-Script.png';
import iconYoutube from '../img/icon-Youtube.png';
import iconWeblink from '../img/icon-Weblink.png';
import iconPhoto from '../img/icon-Photo.png';

import { Stack, Label, Text, DefaultButton, IconButton } from '@fluentui/react';
import React from 'react';
import { isUserSignedIn } from '../SharedCommon/utils';
import i18n from '../i18n';

import { CommandBar, ICommandBarItemProps } from '@fluentui/react/lib/CommandBar';
//import { IButtonProps } from '@fluentui/react/lib/Button';

//import { useConst} from '@fluentui/react-hooks';
import {
  //ContextualMenuItemType,
  IContextualMenuProps
  //IContextualMenuItemProps,
} from '@fluentui/react/lib/ContextualMenu';
//import { Icon } from '@fluentui/react/lib/Icon';

import { exec, init } from 'pell';
import 'pell/dist/pell.css';

import DOMPurify from 'dompurify';

//import { initializeIcons } from '@fluentui/font-icons-mdl2';
import { initializeIcons } from '@fluentui/react/lib/Icons';

import { FontIcon } from '@fluentui/react/lib/Icon';
import { registerIcons } from '@fluentui/react/lib/Styling';

import { ProgressIndicator } from '@fluentui/react/lib/ProgressIndicator';

import {
  PromptExternalLink,
  IPromptExternalLinkProps,
  PromptForScript,
  IPromptForScriptProps,
  PromptDocumentLabel,
  IPromptDocumentLabelProps
} from './Dialogues';

/*
const ProgressIndicatorIndeterminateExample: React.FunctionComponent = () => (
  <ProgressIndicator label="Example title" description="Example description" />
);


const intervalDelay = 100;
const intervalIncrement = 0.01;

export const ProgressIndicatorBasicExample: React.FunctionComponent = () => {
  const [percentComplete, setPercentComplete] = React.useState(0);

  React.useEffect(() => {
    const id = setInterval(() => {
      setPercentComplete((intervalIncrement + percentComplete) % 1);
    }, intervalDelay);
    return () => {
      clearInterval(id);
    };
  });

  return (
    <ProgressIndicator label="Example title" description="Example description" percentComplete={percentComplete} />
  );
};
*/

const iconList = [iconAddText, iconAnswerBox, iconPhoto, iconMediaFile, iconFile, iconWeblink, iconScript, iconYoutube];

const contentType2ItemIdx: { [index: string]: number } = {
  question: 1,
  image: 2,
  audio: 3,
  video: 3,
  document: 4,
  link: 5,
  程序脚本: 6,
  脚本: 6,
  script: 6,
  Youtube: 7,
  Youtube视频: 7,
  网上视频: 7
};

// Use the registerIcons api from the styling package to register custom svg icons so that they
// can be used by the Icon component (or in anything that renders Icons, like Buttons).
const textExt = ['text', 'subTitle', 'title', 'inlineQuestion'];
registerIcons({
  icons: {
    'day1-svg': (
      <svg id='topSvg1' viewBox='0, 0, 50, 40' style={{ display: 'block', height: '40px', padding: 0 }}>
        <path
          className='svgClass'
          id='navbar_path_day1'
          d='M 18, 37 A 17 17 0 0 1 18 3 L 37 3 L 47 20 L 37 37 L 18 37'
        />
        <text className='textClass' id='navbar_text_day1' x='19' y='27'>
          1
        </text>
      </svg>
    ),
    'day2-svg': (
      <svg id='topSvg2' viewBox='40, 0, 50, 40' style={{ display: 'block', height: '40px', padding: 0 }}>
        <path className='svgClass' id='navbar_path_day2' d='M 42, 3 L 74 3 L 84 20 L 74 37 L 42 37 L 52 20 L 42 3' />
        <text className='textClass' id='navbar_text_day2' x='59' y='27'>
          2
        </text>
      </svg>
    ),
    'day3-svg': (
      <svg id='topSvg2' viewBox='40, 0, 50, 40' style={{ display: 'block', height: '40px', padding: 0 }}>
        <path className='svgClass' id='navbar_path_day2' d='M 42, 3 L 74 3 L 84 20 L 74 37 L 42 37 L 52 20 L 42 3' />
        <text className='textClass' id='navbar_text_day2' x='59' y='27'>
          3
        </text>
      </svg>
    ),
    'day4-svg': (
      <svg id='topSvg2' viewBox='40, 0, 50, 40' style={{ display: 'block', height: '40px', padding: 0 }}>
        <path className='svgClass' id='navbar_path_day2' d='M 42, 3 L 74 3 L 84 20 L 74 37 L 42 37 L 52 20 L 42 3' />
        <text className='textClass' id='navbar_text_day2' x='59' y='27'>
          4
        </text>
      </svg>
    ),
    'day5-svg': (
      <svg id='topSvg2' viewBox='40, 0, 50, 40' style={{ display: 'block', height: '40px', padding: 0 }}>
        <path className='svgClass' id='navbar_path_day2' d='M 42, 3 L 74 3 L 84 20 L 74 37 L 42 37 L 52 20 L 42 3' />
        <text className='textClass' id='navbar_text_day2' x='59' y='27'>
          5
        </text>
      </svg>
    ),
    'day6-svg': (
      <svg id='topSvg2' viewBox='40, 0, 50, 40' style={{ display: 'block', height: '40px', padding: 0 }}>
        <path className='svgClass' id='navbar_path_day2' d='M 42, 3 L 74 3 L 84 20 L 74 37 L 42 37 L 52 20 L 42 3' />
        <text className='textClass' id='navbar_text_day2' x='59' y='27'>
          6
        </text>
      </svg>
    ),
    'day7-svg': (
      <svg id='topSvg2' viewBox='40, 0, 50, 40' style={{ display: 'block', height: '40px', padding: 0 }}>
        <path className='svgClass' id='navbar_path_day2' d='M 42, 3 L 74 3 L 84 20 L 74 37 L 42 37 L 52 20 L 42 3' />
        <text className='textClass' id='navbar_text_day2' x='59' y='27'>
          7
        </text>
      </svg>
    ),
    'day8-svg': (
      <svg id='topSvg2' viewBox='40, 0, 50, 40' style={{ display: 'block', height: '40px', padding: 0 }}>
        <path className='svgClass' id='navbar_path_day2' d='M 42, 3 L 74 3 L 84 20 L 74 37 L 42 37 L 52 20 L 42 3' />
        <text className='textClass' id='navbar_text_day2' x='59' y='27'>
          8
        </text>
      </svg>
    ),
    'day9-svg': (
      <svg id='topSvg2' viewBox='40, 0, 50, 40' style={{ display: 'block', height: '40px', padding: 0 }}>
        <path className='svgClass' id='navbar_path_day2' d='M 42, 3 L 74 3 L 84 20 L 74 37 L 42 37 L 52 20 L 42 3' />
        <text className='textClass' id='navbar_text_day2' x='59' y='27'>
          9
        </text>
      </svg>
    ),
    'day10-svg': (
      <svg id='topSvg2' viewBox='40, 0, 50, 40' style={{ display: 'block', height: '40px', padding: 0 }}>
        <path className='svgClass' id='navbar_path_day2' d='M 42, 3 L 74 3 L 84 20 L 74 37 L 42 37 L 52 20 L 42 3' />
        <text className='textClass' id='navbar_text_day2' x='59' y='27'>
          10
        </text>
      </svg>
    ),
    'day11-svg': (
      <svg id='topSvg2' viewBox='40, 0, 50, 40' style={{ display: 'block', height: '40px', padding: 0 }}>
        <path className='svgClass' id='navbar_path_day2' d='M 42, 3 L 74 3 L 84 20 L 74 37 L 42 37 L 52 20 L 42 3' />
        <text className='textClass' id='navbar_text_day2' x='59' y='27'>
          11
        </text>
      </svg>
    ),
    'day12-svg': (
      <svg id='lastSvg' viewBox='40, 0, 50, 40' style={{ display: 'block', height: '40px', padding: 0 }}>
        <path className='svgClass' d=' M 42, 3 L 71 3 A 17 17 0 0 1 71 37 L 42 37 L 52 20 L 42 3' />
        <text className='textClass' x='61' y='27'>
          12
        </text>
      </svg>
    ),
    'last-svg': (
      <svg id='lastSvg' viewBox='40, 0, 50, 40' style={{ display: 'block', height: '40px', padding: 0 }}>
        <path
          className='svgClass'
          id='navbar_path_day2'
          d=' M 42, 3 L 71 3 A 17 17 0 0 1 71 37 L 42 37 L 52 20 L 42 3'
        />
        <text className='textClass' style={{ fontSize: '24px' }} id='navbar_text_day2' x='61' y='27'>
          +
        </text>
      </svg>
    )
  }
});

initializeIcons();

interface IContentEditorProps {
  bookContent: IBookContent;
  sessionId: number;
  setChangeCount: any;
  setChapterTitle: any;

  contentHeight: string;
  headerHeight?: number;
}

interface IContentEditorState {
  bookContent: IBookContent;
  sessionId: number;
  activeTextElement: number;
  sessionOnMouseIdx: number;

  isUploading: boolean;
  uploadingProgress: number;
  uploadingIndex: number; //-1 means append
  filename: string;
  contentHeight: string;

  externLinkProps?: IPromptExternalLinkProps;
  scriptProps?: IPromptForScriptProps;
  promptDocumentLabelProps?: IPromptDocumentLabelProps;
}

const _items: ICommandBarItemProps[] = [
  {
    key: 'text',
    text: i18n.t('authoring.Text'),
    cacheKey: 'myCacheKey' // changing this key will invalidate this item's cache
  },
  {
    key: 'question',
    text: i18n.t('authoring.Question')
  },
  {
    key: 'Image',
    text: i18n.t('authoring.Picture')
  },
  {
    key: 'AV',
    text: i18n.t('authoring.MultiMedia')
  },
  {
    key: 'AV0',
    text: i18n.t('authoring.Document')
  },
  {
    key: 'AV1',
    text: i18n.t('authoring.ExternalLink')
  },
  {
    key: 'AV2',
    text: i18n.t('authoring.Script')
  },
  {
    key: 'AV3',
    text: i18n.t('authoring.Youtube')
  }
];

class ContentEditor extends React.Component<IContentEditorProps, IContentEditorState> {
  private editor: any;
  private saveCount: number;
  private lastSaveSuccess: boolean;
  private hasBlock: boolean;
  private timeoutId?: any;

  constructor(props: IContentEditorProps) {
    super(props);
    this.editor = null;
    this.saveCount = 0;
    this.lastSaveSuccess = true;
    this.hasBlock = false;

    this.state = this.initState(props);
  }

  private initState(props: IContentEditorProps) {
    props.bookContent.sessions.forEach((session) => {
      session.content.forEach((content) => {
        //if these blocks were added manually somehow, add "脚本" for authoring tool purposes
        switch (content.type) {
          case 'html':
          case 'lib':
          case 'js':
          case 'sliderChoice':
          case 'singleChoice':
          case 'multipleChoice':
          case 'session':
            content.block = i18n.t('authoring.Script');
        }
        //disable this prevention of 'more than 1 脚本' --- there is no reason
        // === although, the youtube has a potential double visitation, but it's for the same user.
        // so this.hasBlock will never be set to true, except during the time when the user was inserting it...
        //  to prevent accidental mistakes??
        // there is of course the problem of that variables are referenced randomly??
        //if (content.block)
        //  this.hasBlock = true;
      });
    });

    return {
      bookContent: props.bookContent,
      sessionId: props.sessionId,
      activeTextElement: -1,
      sessionOnMouseIdx: -10,
      isUploading: false,
      uploadingProgress: 0,
      uploadingIndex: -1,
      filename: '',
      contentHeight: props.contentHeight,

      externLinkProps: undefined,
      scriptProps: undefined,
      promptDocumentLabelProps: undefined
    };
  }
  private moveUp = (idx: number) => {
    if (idx === 0) {
      return;
    } // the item is already at the top

    if (this.state.isUploading) {
      alert('there is a file upload in progress, please wait');
      return;
    }

    //this.saveCount++;
    const { bookContent } = this.state;

    const content = bookContent.sessions[this.state.sessionId].content;
    let count = 1;

    //get the whole block, to move the block
    if (content[idx].block) {
      while (count + idx < content.length && content[idx + count].block) {
        count++;
      }
    }

    //first delete the item
    const upItem = content.splice(idx, count);

    //then insert back at a position higher

    /// but if the position up is a block, needs to move above the block
    let upCnt = 1;
    if (content[idx - upCnt].block) {
      while (idx - upCnt - 1 >= 0 && content[idx - upCnt - 1].block) {
        upCnt++;
      }
    }

    content.splice(idx - upCnt, 0, ...upItem);
    this.setState({ bookContent, activeTextElement: -1 }, () => this.saveCount++);
  };

  private _delete = (idx: number) => {
    if (this.state.isUploading) {
      alert('there is a file upload in progress, please wait');
      return;
    }

    //this.saveCount++;
    const { bookContent } = this.state;

    //get the whole block, to move the block
    const content = bookContent.sessions[this.state.sessionId].content;
    let count = 1;
    if (content[idx].block) {
      while (count + idx < content.length && content[idx + count].block) {
        count++;
      }
      this.hasBlock = false; // the only block is deleted;
    }
    bookContent.sessions[this.state.sessionId].content.splice(idx, count);
    this.setState({ bookContent, activeTextElement: -1 }, () => this.saveCount++);
  };

  private moveDown = (idx: number) => {
    if (this.state.isUploading) {
      alert('there is a file upload in progress, please wait');
      return;
    }

    //get the whole block, to move the block
    //const bookContent = { ...this.state.bookContent } as IBookContent;
    const { bookContent } = this.state;
    const content = bookContent.sessions[this.state.sessionId].content;
    let count = 1;
    if (content[idx].block) {
      while (count + idx < content.length && content[idx + count].block) {
        count++;
      }
    }

    if (idx === content.length - count) {
      return;
    } // the item is already at the bottom

    //this.saveCount++;

    //first delete the item
    const downItem = content.splice(idx, count);

    //then insert back at a position higher

    /// but if the position up is a block, needs to move above the block
    let downCnt = 1;
    if (content[idx].block) {
      while (downCnt + idx < content.length && content[idx + downCnt].block) {
        downCnt++;
      }
    }

    content.splice(idx + downCnt, 0, ...downItem);
    this.setState({ bookContent, activeTextElement: -1 }, () => this.saveCount++);
  };

  private menuProps = (idx: number): IContextualMenuProps => {
    return {
      shouldFocusOnMount: true,
      items: [
        {
          key: 'MoveUp',
          iconProps: { iconName: 'Up' },
          text: i18n.t('authoring.MoveUp'), //Move up
          //text: "Move up",
          onClick: () => this.moveUp(idx)
        },
        {
          key: 'Delete',
          iconProps: { iconName: 'Delete' },
          text: i18n.t('authoring.Delete'), //Delete
          //text: "Delete",
          onClick: () => this._delete(idx)
        },
        {
          key: 'MoveDown',
          iconProps: { iconName: 'Down' },
          text: i18n.t('authoring.MoveDown'), //Move Down
          //text: "Move Down",
          onClick: () => this.moveDown(idx)
        }
      ]
    };
  };

  private onClicks = (cmdIdx: number) => {
    switch (cmdIdx) {
      case 0:
        this._addText();
        break;
      case 1:
        this._addQBox();
        break;
      case 2:
        this._addImage(-1);
        break;
      case 3:
        this._addAV(-1);
        break;
      case 4:
        this._addDocument(-1);
        break;
      case 5:
        this._addExternalLink();
        break;
      case 6:
        this._addComingSundaySermon();
        break;
      case 7:
        this._addYoutube();
        //this._addSundaySermon();
        break;
      /* these are not implemented yet
      case 6:
        this._addFromClass();
        break;
      */
    }
  };

  //                marginLeft:index===0?'8%':0}
  private contentEditorCommandBar = (onClicks: any) => {
    _items.forEach((item, index) => {
      item.onClick = () => onClicks(index);
      item.buttonStyles = {
        flexContainer: { flexWrap: 'wrap', width: '40px' }
      };
      item.onRenderIcon = () => (
        <img
          src={iconList[index]}
          alt={item.text}
          style={{ maxWidth: '90%', maxHeight: '90%', marginBottom: '2px' }}></img>
      );
    });
    /*
    overflowItems={_overflowItems}
    overflowButtonProps={overflowProps}
    farItems={_farItems}
          IsDynamicOverflowEnabled='false'
          div:{width:'12%',minWidth:'34px',maxWidth:'50px',marginRight:'20px'}
    */
    return (
      <div style={{ padding: '0 4px', transform: 'translateY(-4px)' }}>
        <CommandBar
          styles={{
            root: {
              padding: '0px',
              div: { justifyContent: 'space-between' },
              button: { margin: '0px', padding: '0px' },
              span: { margin: '0px' }
            }
          }}
          items={_items}
          //farItems={_farItems}
          //overflowItems={_farItems}
          //overflowBehavior={true}
          //overflow={true}
          ariaLabel='Use left and right arrow keys to navigate between commands'
        />
      </div>
    );
  };

  private editCommandBox(): React.ReactNode {
    return (
      <>
        <div style={{ height: '70px', border: '1px dashed blue', width: '90%' }}></div>
        <div
          style={{
            marginLeft: '45%',
            background: 'white',
            border: '1px blue',
            borderTopStyle: 'dashed',
            borderLeftStyle: 'dashed',
            width: '110px',
            height: '110px',
            transform: 'translateY(-30px) translateX(-50%) rotate(45deg)',
            overflow: 'hidden'
          }}></div>
        <div
          style={{
            height: '110px',
            border: '1px dashed blue',
            width: '90%',
            padding: 0,
            background: 'white',
            transform: 'translateY(-90px)'
          }}>
          <p style={{ textAlign: 'center', transform: 'translateY(-18px)' }}>
            <span style={{ backgroundColor: 'white' }}>{i18n.t('authoring.AddContent')}</span>
          </p>
          {this.contentEditorCommandBar(this.onClicks)}
        </div>
        {!!this.state.promptDocumentLabelProps && (
          <PromptDocumentLabel
            label={this.state.promptDocumentLabelProps.label}
            onExit={this.state.promptDocumentLabelProps.onExit}
            idx={this.state.promptDocumentLabelProps.idx}
          />
        )}
        {!!this.state.externLinkProps && (
          <PromptExternalLink
            url={this.state.externLinkProps.url}
            label={this.state.externLinkProps.label}
            onExit={this.state.externLinkProps.onExit}
            idx={this.state.externLinkProps.idx}
          />
        )}
        {!!this.state.scriptProps && (
          <PromptForScript
            jsonTxt={this.state.scriptProps.jsonTxt}
            onExit={this.state.scriptProps.onExit}
            idx={this.state.scriptProps.idx}
            iii={this.state.scriptProps.iii}
          />
        )}
      </>
    );
    /*
      <div style="height:100px;border:1px dashed blue"></div>
      <div style="margin-left:50%;background:white;border:1px dashed blue; width:100px;height:100px;
                      transform:translateY(-30px) translateX(-50%) rotate(45deg);overflow:hidden">
      </div>
      
      <div style="height:100px;border:1px dashed blue;background:white; transform:translateY(-80px)">
      <p style='text-align:center; transform:translateY(-28px)'><span style='background-color:white'>click here to add</span></p>
      <input type='button' value='text'></input>
      </div>
*/
  }

  private imgIconForContent = (icon: any, text?: string) => {
    return (
      <img
        src={icon}
        alt={text}
        style={{
          maxWidth: '25px',
          maxHeight: '25px',
          paddingTop: '4px',
          float: 'left'
        }}></img>
    );
  };

  private click2UpdateRow = (idx: number) => {
    const content = this.state.bookContent.sessions[this.state.sessionId].content[idx];
    switch (content.block || content.special || content.type) {
      case 'image':
        this._addImage(idx);
        return;
      case 'link':
        this._addExternalLink(idx);
        return;
      case 'audio':
      case 'video':
        this._addAV(idx);
        return;
      case 'document':
        this._addDocument(idx);
        return;
      case '程序脚本':
      case '脚本':
      case 'script':
        this._addComingSundaySermon(idx);
        return;
      case 'Youtube': // new youtube, you can add multiple
        this._addYoutube(idx);
        return;
      case 'Youtube视频': // the old block that only allow one
      case '网上视频':
        this._addSundaySermon(idx);
        return;
      case 'question':
      //it's possible to change the question ID
      //but perhaps keep it silent, since it's a generated ID and we don't want
      //people to change it and caused dups.  Rarely people need to change the ID
    }
  };

  private button2Edit = (iconType: string, displayText: string, idx: number) => {
    const icon = iconList[contentType2ItemIdx[iconType]];
    /*
    return <>
      { this.imgIconForContent(iconList[contentType2ItemIdx[iconType]], iconType) }
      <Text style={{ marginLeft: "4px", lineHeight: "30px" }}>
        {displayText}
      </Text>
    </>
    */
    return (
      <DefaultButton
        style={{
          paddingLeft: '5px',
          paddingRight: '5px',
          width: '100%',
          height: '100%',
          background: '#d3d3d3'
        }}
        styles={{
          flexContainer: {
            justifyContent: 'left'
            //marginLeft: 25,
          }
        }}
        onClick={() => this.click2UpdateRow(idx)}>
        <img
          src={icon}
          alt={iconType}
          style={{
            maxWidth: '25px',
            maxHeight: '25px',
            paddingTop: '4px',
            float: 'left',
            marginRight: '5px'
          }}></img>
        {displayText}
      </DefaultButton>
    );
  };

  render(): React.ReactNode {
    console.log(this.props);
    if (!this.state.bookContent.sessions) {
      return <p> Data need to be repaired</p>;
    }
    const nnn = this.state.bookContent.sessions.length;
    //this.hasBlock = false;
    return (
      <>
        <div id='idHeader' className='ColumnHead'>
          {i18n.t('authoring.ContentEditor')}
        </div>
        <div className='ContentV3' style={{ height: this.state.contentHeight }}>
          <Stack tokens={{ childrenGap: 3 }}>
            <h3 style={{ textAlign: 'center', width: 'calc(90% - 14px)' }}>{i18n.t('authoring.ChapterTitle')}</h3>
            <input
              value={this.state.bookContent.title}
              onChange={this._updateTitle}
              style={{ padding: '5px', width: 'calc(90% - 14px)' }}
            />
            <div className='studySessionsNav'>
              <span style={{ position: 'relative', top: '-13px' }}>{i18n.t('authoring.NewDivision')}</span>
              {this.state.bookContent.sessions &&
                this.state.bookContent.sessions.map((session, idx) => {
                  return (
                    <FontIcon
                      aria-label={`day${idx + 1}`}
                      iconName={`day${idx + 1}-svg`}
                      key={idx}
                      className={idx === this.state.sessionId ? 'activeSvgText' : 'inActiveSvgText'}
                      style={{
                        marginLeft: idx === 0 ? '10px' : '38px',
                        marginRight: idx === 0 ? '3px' : '0px'
                      }}
                      onClick={() => {
                        if (idx !== this.state.sessionId) {
                          this.setState({
                            sessionId: idx,
                            activeTextElement: -1
                          });
                        }
                      }}
                      onMouseEnter={() => {
                        this.setState({ sessionOnMouseIdx: idx });
                      }}
                      /*
                  onMouseLeave={()=>{
                    this.setState({sessionOnMouseIdx:-10});
                  }}
                  */
                    />
                  );
                })}
              {nnn < 12 && (
                <FontIcon
                  aria-label='addSession'
                  iconName={'last-svg'}
                  className='AddSessionSvgText'
                  style={{ marginLeft: '38px' }}
                  onClick={this._addSession}
                />
              )}
              {this.state.bookContent.sessions && this.state.bookContent.sessions.length > 1 && (
                <div
                  className='dropdown4SessionNav'
                  style={{
                    left: this.state.sessionOnMouseIdx * 40 + 55 + 10
                  }}>
                  <IconButton
                    aria-label='MoveLeft'
                    iconProps={{ iconName: 'PageLeft' }}
                    className='iconNavCmdClass'
                    onClick={() => this._moveSessionLeft(this.state.sessionOnMouseIdx)}
                  />
                  <IconButton
                    aria-label='DeleteSession'
                    iconProps={{ iconName: 'Delete' }}
                    className='iconNavCmdClass'
                    onClick={() => this._deleteSession(this.state.sessionOnMouseIdx)}
                  />
                  <IconButton
                    aria-label='MoveRight'
                    iconProps={{ iconName: 'PageRight' }}
                    className='iconNavCmdClass'
                    onClick={() => this._moveSessionRight(this.state.sessionOnMouseIdx)}
                  />
                </div>
              )}
            </div>
            <h3 style={{ textAlign: 'center', width: 'calc(90% - 14px)' }}>{i18n.t('authoring.DivisionContent')}</h3>
            {this.state.bookContent.sessions[this.state.sessionId] &&
              this.state.bookContent.sessions[this.state.sessionId].content &&
              this.state.bookContent.sessions[this.state.sessionId].content.map((content, idx, arr) =>
                this.state.isUploading && this.state.uploadingIndex === idx ? (
                  <ProgressIndicator
                    key={idx}
                    label={i18n.t('authoring.UploadProgress') + this.state.filename}
                    description={'please wait...'}
                    percentComplete={this.state.uploadingProgress}
                  />
                ) : (
                  (!content.block || idx === 0 || !arr[idx - 1].block) && (
                    <div key={100 + idx} style={{ display: 'flex' }}>
                      <div
                        key={idx}
                        id={idx.toString()}
                        style={{
                          border: '1px solid black',
                          textAlign: 'left',
                          width: '90%',
                          marginRight: '4px'
                        }}
                        onMouseEnter={() => this._onMouseEnter(idx)}>
                        {this._renderContentBlock(content, idx)}
                      </div>

                      <DefaultButton
                        title='More'
                        ariaLabel='More'
                        key={1000 + idx}
                        style={{
                          float: 'right',
                          border: '1px solid black',
                          width: '30px',
                          minWidth: '30px',
                          borderRadius: '50%',
                          height: '30px'
                        }}
                        menuIconProps={{ iconName: 'More' }}
                        menuProps={this.menuProps(idx)}
                      />
                    </div>
                  )
                )
              )}
            {this.state.isUploading && this.state.uploadingIndex === -1 && (
              <ProgressIndicator
                label={i18n.t('authoring.UploadProgress') + this.state.filename}
                description='please wait...'
                percentComplete={this.state.uploadingProgress}
              />
            )}
            {!this.state.isUploading && this.editCommandBox()}
          </Stack>
        </div>
      </>
    );
  }

  private _renderContentBlock = (content: IContent, idx: number): React.ReactNode => {
    //console.log('_renderContentBlock: ', content);
    const ttt = content.block || content.special || content.type;
    let displayTxt = '';
    if (ttt === 'document') {
      displayTxt = `${_items[contentType2ItemIdx[ttt]].text}: ${content.value}: ${content.url ?? ''}`;
    }

    switch (ttt) {
      case 'inlineQuestion':
      case 'title':
      case 'subTitle':
      case 'text': {
        if (this.state.activeTextElement === idx) {
          return <div id='idActiveEditElement' className='pell' style={{ height: 'auto' }} />;
        } else {
          let textValue = content.value as string;
          /*
          if (ttt === "inlineQuestion") {
            textValue = textValue.replace(/{([A-Za-z_0-9]*)}/g, (match)=>{
              return `<input readonly value = '${match}' style='width:10em;background:#d3d3d3;text-align:center'/>`;
            });
          }
          */

          //textValue = textValue?.replace(/\n/g, "<br>");
          textValue = DOMPurify.sanitize(textValue, {
            USE_PROFILES: { html: true }
          });

          return (
            <div
              style={{
                height: 'auto',
                width: '100%',
                border: 'none',
                padding: '5px'
              }}
              dangerouslySetInnerHTML={{ __html: textValue || '' }}
            />
          );
          //return (<Text>{content.value as string}</Text>);
          //return (<p>{content.value as string}</p>);
        }
      }
      case 'document':
      case 'audio':
      case 'video':
      case 'image':
      case 'question':
      case 'link':
      case '程序脚本':
      case '脚本':
      case 'script':
      case 'Youtube':
      case 'Youtube视频':
      case '网上视频':
        displayTxt =
          displayTxt ||
          content.block ||
          content.special ||
          `${_items[contentType2ItemIdx[ttt]].text}: ${content.value}${' '}
              ${content.url ?? ''}`;
        return this.button2Edit(ttt, displayTxt, idx);
      case 'html':
      case 'lib':
      case 'js':
        return (
          <Text style={{ padding: '5px' }}>
            <>
              This is {content.type}:{content.value}
            </>
          </Text>
        );
      case 'sliderChoice':
      case 'singleChoice':
      case 'multipleChoice':
      case 'session':
        return (
          <Text style={{ padding: '5px' }}>
            This is {content.type}:{content.title}
          </Text>
        );
      case 'bible':
        return (
          <>
            <label>{i18n.t('authoring.Read')}</label>
            {(content.value as IReferenceValue[]).map((reference: IReferenceValue, idx) => {
              return <Label key={idx}>{`${reference.book} ${reference.verse}`}</Label>;
            })}
          </>
        );
    }
  };

  private _onMouseEnter = (activeTextElement: number) => {
    if (this.state.activeTextElement !== activeTextElement) {
      const content = this.state.bookContent.sessions[this.state.sessionId].content[activeTextElement];
      if (textExt.indexOf(content.type) !== -1 && !content.block && !content.special) {
        this.setState({ activeTextElement });
      }
    }
  };

  private _deleteSession = (idx: number) => {
    if (this.state.isUploading) {
      alert('there is a file upload in progress, please wait');
      return;
    }

    //this.saveCount++;
    const { bookContent } = this.state;
    bookContent.sessions.splice(idx, 1);

    let { sessionId } = this.state;

    //deleting the current active session
    if (bookContent.sessions.length <= sessionId) {
      sessionId = bookContent.sessions.length - 1;
    }

    this.setState({ bookContent, sessionId, activeTextElement: -1 }, () => this.saveCount++);
  };

  private _moveSessionLeft = (idx: number) => {
    if (idx === 0) {
      return;
    } // the item is already at the left

    if (this.state.isUploading) {
      alert('there is a file upload in progress, please wait');
      return;
    }

    //this.saveCount++;
    const { bookContent } = this.state;
    const item = bookContent.sessions.splice(idx, 1);

    //then insert back at a position higher
    bookContent.sessions.splice(idx - 1, 0, ...item);
    this.setState({ bookContent, activeTextElement: -1 }, () => this.saveCount++);
  };

  private _moveSessionRight = (idx: number) => {
    if (idx === this.state.bookContent.sessions.length - 1) {
      return;
    } // the item is already at the right

    if (this.state.isUploading) {
      alert('there is a file upload in progress, please wait');
      return;
    }

    //this.saveCount++;
    const { bookContent } = this.state;
    const item = bookContent.sessions.splice(idx, 1);

    //then insert back at a position higher
    bookContent.sessions.splice(idx + 1, 0, ...item);
    this.setState({ bookContent, activeTextElement: -1 }, () => this.saveCount++);
  };

  private _addSession = () => {
    //this.saveCount++;
    const { bookContent } = this.state;
    const nnn = bookContent.sessions.length;
    const session = { title: '', content: [] } as IBookContentSession;
    bookContent.sessions.push(session);
    this.setState({ bookContent, sessionId: nnn, activeTextElement: -1 }, () => this.saveCount++);
  };

  private _updateTitle = (ev: React.ChangeEvent<HTMLInputElement>) => {
    //this.saveCount++; // need to save the title of the content, though same as the chapter title

    const title = ev.target.value;

    this.props.setChapterTitle(title);

    const { bookContent } = this.state;
    bookContent.title = title;
    this.setState({ bookContent, activeTextElement: -1 }, () => this.saveCount++);
  };

  private _addText = () => {
    const { bookContent } = this.state;
    const activeTextElement =
      bookContent.sessions[this.state.sessionId].content.push({
        type: 'text',
        value: i18n.t('authoring.EnterSomeText')
      }) - 1;
    this.setState({ bookContent, activeTextElement }, () => this.saveCount++);
    //this.saveCount++;
  };

  private _addQBox = () => {
    const timeStamp = Math.floor(Date.now());
    const value = 'QiD' + timeStamp;

    const { bookContent } = this.state;
    bookContent.sessions[this.state.sessionId].content.push({
      type: 'question',
      value
    });
    this.setState({ bookContent }, () => this.saveCount++);
    //this.saveCount++;
  };

  private _uploadFile = (idx: number, filetype: string, callback: any) => {
    const inputF = document.createElement('input');
    inputF.setAttribute('type', 'file');
    inputF.setAttribute('accept', filetype);

    inputF.onchange = (e): void => {
      // eslint-disable-next-line
      const tg:any = e?.target;
      const file = tg?.files[0];
      const filename = file?.name;
      //alert('The file "' + filename + '" has been selected.');
      if (!file || !filename) {
        return;
      }

      /* problem: the file extension are not case sensitive,
        // but the validation code below is case sensitive.
        // disable this section of the code for now.
        // in general, the file filter works well already,
        // and don't expect the user to override in process
      const validExtension = filetype.split(",");
      const idx = validExtension.find((ext) => {
        return filename.indexOf(ext) !== -1;
      });
      if (idx === undefined) {
        alert(`please only upload files with extension: ${filetype}`);
        return;
      }
      */

      //var fileInput = document.getElementById('the-file');
      this.setState({
        isUploading: true,
        uploadingProgress: 0,
        uploadingIndex: idx,
        filename
      });
      _dataProvider
        .uploadFile(this.state.bookContent.lessonId || '', file, (uploadingProgress: number) => {
          this.setState({ uploadingProgress });
        })
        .then((success) => {
          if (!success) {
            alert('upload failed, try again! you may need to refresh page');
            return;
          }
          //this.saveCount++;
          callback(file!.newName || filename);

          this.setState(
            {
              isUploading: false,
              uploadingProgress: 0,
              uploadingIndex: -1
            },
            () => this.saveCount++
          );
          return;
        });
    };
    inputF.click();
    return true;
  };

  private _addImage = (idx: number) => {
    this._uploadFile(idx, '.png,.jpg,.jpeg', (url: string) => {
      const { bookContent } = this.state;
      if (idx === -1) {
        bookContent?.sessions[this.state.sessionId]?.content.push({
          type: 'image',
          value: url
        });
      } else {
        //if (bookContent?.sessions[this.state.sessionId]?.content[idx].type !== "image")
        const content = bookContent?.sessions[this.state.sessionId]?.content[idx];
        content.value = url;
      }
      this.setState({ bookContent });
    });
  };

  private _addAV = (idx: number) => {
    this._uploadFile(idx, '.mp3,.mp4,.webm,.ogg,.m4a', (filename: string) => {
      const { bookContent } = this.state;
      let type;
      if (filename.endsWith('.mp3') || filename.endsWith('.m4a')) {
        type = 'audio';
      } else if (filename.endsWith('.mp4') || filename.endsWith('.ogg') || filename.endsWith('.webm')) {
        type = 'video';
      } // if unrecognized file, stop here.
      else {
        alert('Failed: non-audio/video filetype is uploaded'); // this should never really happen
        return;
      }
      if (idx === -1) {
        bookContent?.sessions[this.state.sessionId]?.content.push({
          type,
          value: filename
        });
      } else {
        const content = bookContent?.sessions[this.state.sessionId]?.content[idx];
        content.type = type;
        content.value = filename;
      }

      this.setState({ bookContent });
    });
  };
  /*
    //need to create a new document type
    {
     "type": "link",
     "value": "PPT",
     "url": "http://idigest.gtinternational.org/files/sharing-gospel-分享福音.pptx",
     "openInOtherApp": true
    },
  a) #3's cancel -- could give the user a sense to cancel the "entire" upload....
  Can we just have "save" since it already have a defaulted 默认的文件名?
  b) if the user enters nothing <or enter a blank>, do we need to handle this special user error case?
  c) if after the upload, the user clicks on it to edit,
  does the user go through the whole flow again?
  or do we just prompt the user to update the 文件的标题?
  */
  private _addDocument = (idx: number) => {
    const { bookContent } = this.state;

    if (idx >= 0) {
      const content = bookContent?.sessions[this.state.sessionId]?.content[idx];
      // for modification, we'll only allow editing the label
      // if the user really intend to upload a new file, he should delete this
      // and then upload a new file, new label etc.
      const promptDocumentLabelProps: IPromptDocumentLabelProps = {
        label: content.value as string,
        onExit: (label: string) => {
          if (!label || label === content.value) {
            //treat both case as no op
            this.setState({ promptDocumentLabelProps: undefined });
            return;
          } else {
            content.value = label;
            this.setState({ bookContent, promptDocumentLabelProps: undefined }, () => this.saveCount++);
          }
        },
        idx
      };
      this.setState({ promptDocumentLabelProps });
      return;
    }

    this._uploadFile(idx, '.pdf,.pptx,.ppt,.docx,.doc,.xlsx,.xls', (filename: string) => {
      /// ... in progress
      const promptDocumentLabelProps: IPromptDocumentLabelProps = {
        label: filename,
        onExit: (label: string) => {
          if (label) {
            // if the label is blank, there won't be a new node created.
            // this supports the upload of docuemnts without a node.
            // This would allow the scenario that some class content might have
            // internal link to this uploaded document, just not via this upload
            bookContent.sessions[this.state.sessionId].content.push({
              type: 'link',
              value: label,
              url: filename,
              special: 'document'
            });
            this.setState({ bookContent, promptDocumentLabelProps: undefined }, () => this.saveCount++);
          } else {
            this.setState({ promptDocumentLabelProps: undefined });
          }
        },
        idx
      };
      this.setState({ promptDocumentLabelProps });
    });
  };

  private _addExternalLink = (idx?: number) => {
    const { bookContent } = this.state;
    let content = undefined;
    if (typeof idx === 'number') {
      content = bookContent?.sessions[this.state.sessionId]?.content[idx];
    } else {
      idx = -1;
    }
    console.log(idx, content);
    const externLinkProps: IPromptExternalLinkProps = {
      label: !content ? '' : (content.value as string),
      url: !content ? '' : content.url ?? '',
      onExit: this._addExternalLinkExit,
      idx
    };
    console.log(idx, externLinkProps);
    this.setState({ externLinkProps });
    //PromptExternalLink(props);
    return;
  };

  private _addExternalLinkExit = (url: string, label: string, idx: number) => {
    if (!url) {
      this.setState({ externLinkProps: undefined });
      return;
    }
    //this.saveCount++;

    const { bookContent } = this.state;

    if (idx === -1) {
      bookContent.sessions[this.state.sessionId].content.push({
        type: 'link',
        value: label,
        url,
        openInOtherApp: true
      });
    } else {
      const content = bookContent?.sessions[this.state.sessionId]?.content[idx];
      //content.type = type;
      content.value = label;
      content.url = url;
    }

    this.setState({ bookContent, externLinkProps: undefined }, () => this.saveCount++);
  };

  private _addYoutube = (idx?: number) => {
    const { bookContent } = this.state;
    let oldUrl = '';
    if (typeof idx === 'number') {
      const vs = bookContent?.sessions[this.state.sessionId]?.content[idx].value as string;
      const vvv = vs.split(' ');
      oldUrl = vvv[3].slice(5, -1);
    }
    let url = window.prompt('Enter Youtube URL', oldUrl);
    if (!url) {
      return;
    }

    const qIdx = url.indexOf('youtube.com/watch?v=');
    if (qIdx !== -1) {
      //need to do more:
      //1) there are query paramters with &, and since ?v= is gone, it's no longer a valid url
      //2) on the browser, the query paramter "&t=#s" needs to be converted to be "?start=#"
      const qqq = url.substring(qIdx + 20);
      const qaaa = qqq.split('&');
      url = url.substring(0, qIdx + 12);
      url += 'embed/';
      url += qaaa[0];

      for (let iii = 1; iii < qaaa.length; iii++) {
        url += iii === 1 ? '?' : '&';
        url += qaaa[iii].startsWith('t=') ? 'start=' + qaaa[iii].slice(2, -1) : qaaa[iii];
      }
      //url = url.replace("youtube.com/watch?v=", "youtube.com/embed/");
    }

    //TODO: a user might enter a short url, to address this,
    //  we would need to do a 'fetch', and get status 303
    //      and then inspect the location header like below, and then use this url instead
    //      location: https://www.youtube.com/watch?v=6U841t6LK5k&feature=youtu.be
    ///*** we need to delete a few rows, and replace it with the new one...
    //    so its insert into the middle with the new array
    // *** actually, we only need to replace one row -- the first row, as the other rows are
    // the same as before, and need no change ****
    /***
     ***/

    //this.saveCount++;

    const value = `<iframe width='100%' height='315' src='${url}' title='YouTube video player' frameborder='0' allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture' allowfullscreen></iframe>`;
    if (typeof idx !== 'number') {
      bookContent.sessions[this.state.sessionId].content.push({
        type: 'text',
        value,
        special: 'Youtube'
      });
    } else {
      const content = bookContent?.sessions[this.state.sessionId]?.content[idx];
      content.value = value;
    }

    this.setState({ bookContent }, () => this.saveCount++);
  };

  //text: "网上视频",
  //text: "程序脚本",
  // below is not used, but it's for youtube sunday sermon, in order
  // to record the attendance.
  // the new _addYoutube() don't have this attendance, but then
  // each page can have multiple youtube, multiple worship hymsn, etc.
  private _addSundaySermon = (idx?: number) => {
    const { bookContent } = this.state;
    let oldUrl = '';
    if (this.hasBlock) {
      if (typeof idx !== 'number') {
        alert('A session can have only one Youtube视频 and/or 脚本. You can delete the existing one and try again');
        return;
      } else {
        const vs = bookContent?.sessions[this.state.sessionId]?.content[idx].value as string;
        const vvv = vs.split(' ');
        oldUrl = vvv[3].slice(5, -1);
      }
    }
    let url = window.prompt('Enter Youtube URL', oldUrl);
    if (!url) {
      return;
    }

    //this.hasBlock = true;
    if (url.indexOf('youtube.com/watch?v=') !== -1) {
      url = url.replace('youtube.com/watch?v=', 'youtube.com/embed/');
    }

    //TODO: a user might enter a short url, to address this,
    //  we would need to do a 'fetch', and get status 303
    //      and then inspect the location header like below, and then use this url instead
    //      location: https://www.youtube.com/watch?v=6U841t6LK5k&feature=youtu.be
    ///*** we need to delete a few rows, and replace it with the new one...
    //    so its insert into the middle with the new array
    // *** actually, we only need to replace one row -- the first row, as the other rows are
    // the same as before, and need no change ****
    /***
     ***/

    //this.saveCount++;

    if (typeof idx !== 'number') {
      bookContent.sessions[this.state.sessionId].content.push({
        type: 'text',
        value: `<iframe width='100%' height='315' src='${url}' title='YouTube video player' frameborder='0' allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture' allowfullscreen></iframe>`,
        block: 'Youtube视频'
      });
      bookContent.sessions[this.state.sessionId].content.push({
        type: 'lib',
        value:
          "function logPageView1(pageId) { fetch(`${content.pageViewUrl}/${pageId}`, { method: 'POST', headers: { Authorization: `Bearer ${content.accessToken}`} }); }",
        block: 'Youtube视频'
      });
      bookContent.sessions[this.state.sessionId].content.push({
        type: 'lib',
        value: "logPageView1('SundaySermon')",
        block: 'Youtube视频'
      });
    } else {
      const content = bookContent?.sessions[this.state.sessionId]?.content[idx];
      content.value = `<iframe width='100%' height='315' src='${url}' title='YouTube video player' frameborder='0' allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture' allowfullscreen></iframe>`;
    }

    this.setState({ bookContent }, () => this.saveCount++);
  };

  private _addComingSundaySermon = (idx?: number) => {
    if (this.hasBlock && typeof idx !== 'number') {
      alert('A session can have only one Youtube视频 and/or 脚本. You can delete the existing one and try again');
      return;
    }

    let jsonTxt =
      `[{"type":"text",
        "value":
        "<br><h2><div id='playerStatus' style='color:red;text-align:center;'></div></h2><iframe id='player' width='100%' height='0' src='' title='YouTube video player' frameborder='0' allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture' allowfullscreen></iframe>"},
        {"type": "lib",
        "value":
        "function logPageView(e)` +
      "{fetch(`${content.pageViewUrl}/${e}`,{method:'POST',headers:{Authorization:`Bearer ${content.accessToken}`}})}var waitIndicator=0;function setLiveVideo(){fetch(`https://idigest.gtinternational.org/api/live/truelight?date=${content.title}`).then(e=>e.json()).then(e=>{var t=document.getElementById('player'),o=document.getElementById('playerStatus');return'notstarted'===e.status.toLowerCase()?(o.innerText='敬拜直播尚未开始，请耐心等待 '+'◴◷◶◵'[waitIndicator++%'◴◷◶◵'.length],void setTimeout(()=>{setLiveVideo()},1e3)):'live'===e.status.toLowerCase()?(o.innerText='正在直播',t.height=315,t.src=e.url):'notready'===e.status.toLowerCase()?(o.innerText='直播结束，录像尚未就绪，请耐心等待 '+'◴◷◶◵'[waitIndicator++%'◴◷◶◵'.length],void setTimeout(()=>{setLiveVideo()},1e3)):void('ready'===e.status.toLowerCase()&&(o.innerText='',t.height=315,t.src=e.url))}).catch(e=>console.log(e))} setLiveVideo(); logPageView('SundaySermon')" +
      '"}]';

    let iii = 0;
    if (typeof idx !== 'number') {
      idx = -1;
    } else {
      const { bookContent } = this.state;
      let content = bookContent?.sessions[this.state.sessionId]?.content[idx];
      const blockContent = [];
      while (content && content.block) {
        blockContent.push(content);
        iii++;
        content = bookContent?.sessions[this.state.sessionId]?.content[idx + iii];
      }
      jsonTxt = JSON.stringify(blockContent);
    }

    /// the sample script is for truelight's sunday
    const scriptProps: IPromptForScriptProps = {
      jsonTxt,
      onExit: this._addComingSundaySermonExit,
      idx,
      iii
    };
    this.setState({ scriptProps });
    return;
  };

  private _addComingSundaySermonExit = (jsonTxt: string, idx: number, iii: number) => {
    if (!jsonTxt) {
      this.setState({ scriptProps: undefined });
      return;
    }
    let jsonObj;
    try {
      jsonObj = JSON.parse(jsonTxt);
      if (!jsonObj || jsonObj.length === undefined) {
        alert('脚本 must be an array of json objects');
        return;
      }
    } catch (e) {
      console.log(e);
      alert('脚本 must be an array of json objects');
      return;
    }

    //this.hasBlock = true;

    //this.saveCount++;
    const { bookContent } = this.state;

    jsonObj.forEach((item: any) => (item.block = '脚本'));

    if (idx === -1) {
      bookContent.sessions[this.state.sessionId].content =
        bookContent.sessions[this.state.sessionId].content.concat(jsonObj);
    } else {
      // delete iii elements from position idx, and then insert ...jsonObj
      bookContent.sessions[this.state.sessionId].content.splice(idx, iii, ...jsonObj);
    }
    this.setState({ bookContent, scriptProps: undefined }, () => this.saveCount++);
  };

  //private _addFromClass = () => {};

  public componentWillUnmount(): void {
    console.log('ContentEditor componentWillUnmount: ');
    clearTimeout(this.timeoutId);
  }

  public componentDidUpdate(): void {
    console.log('ContentEditor componentDidUpdate');
    this._initTextEditor();
  }

  public componentDidMount(): void {
    console.log('ContentEditor componentDidMount: ', this.props.contentHeight, this.props.headerHeight);
    this.saveEvery5Seconds(0);
    if (!this.props.headerHeight) {
      return;
    }

    this.setState({
      contentHeight: `${parseInt(this.props.contentHeight) - document!.getElementById('idHeader')!.clientHeight}px`
    });
    /*
    const hh =
      document!.documentElement!.clientHeight -
      document!.getElementById("idHeader")!.clientHeight -
      document!.documentElement!.clientHeight / 100;
    const contentHeight = `${hh - this.props.headerHeight}px`;
    this.setState({ contentHeight });
    */
    //this.props.setcontentHeight(contentHeight);
    this._initTextEditor();
  }

  //this doesn't work ===
  simplifyHtml = (notesContent: string) => {
    //<div style=\"\">
    //<div style=\"text-align: left;\">
    //the below changes the content??? --- we'll need to do a setState to cause the phone emulator to work??
    console.log('the html: ', notesContent);

    const htmlKeep2Div = notesContent.replace(/<\/div><div>/g, '</div>^^^<div>');

    let onlyKeepBr = htmlKeep2Div.replace(/<div><br><\/div>/g, '<br><br>');

    onlyKeepBr = onlyKeepBr.replace(/<div>(.*?)<\/div>/g, (match, p1) => {
      const brOnly = p1.replace(/^(<font .*?>|<b>|<i>|<u>)*<br>(<\/font>|<\/b>|<\/i>|<\/u>)*/, '<br>');
      if (brOnly === '<br>') {
        return '<br><br>';
      } else {
        return match;
      } /// be conservative --- don't touch it if it contains <br> in the middle somewhere
    });

    let giveBrForEachDiv = onlyKeepBr.replace(/<div>(.*?)<\/div>/g, '<br>$1<br>');

    giveBrForEachDiv = giveBrForEachDiv.replace(/<br>\^\^\^<br>/g, '<br>');

    if (giveBrForEachDiv.startsWith('<br>')) {
      giveBrForEachDiv = giveBrForEachDiv.slice(4);
    }

    console.log('giveBrForEachDiv: ', giveBrForEachDiv);
    return giveBrForEachDiv;
  };

  //this DidUpdate() method is called after re-render, and so need to reset init for this.editor??
  // --- but how to close the pell editor???
  private _initTextEditor(): void {
    if (this.state.activeTextElement === -1) {
      //there is no text element yet
      return;
    }
    if (this.editor !== null) {
      return;
    }
    /*
    console.log("ContentEditor _initTextEditor: ", this.state.activeTextElement,
      this.state.bookContent.sessions[this.state.sessionId].content[
        this.state.activeTextElement
      ].value);
    */

    this.editor = init({
      element: document.getElementById('idActiveEditElement')!,
      onChange: (notesContent) => {
        //const giveBrForEachDiv = this.simplifyHtml(notesContent);

        //some browser adds unnecesary <div>\n</div>
        notesContent = notesContent.replace(/<div>\n<\/div>/g, '');
        notesContent = notesContent.replace(/<div><b>\n<\/b><\/div>/g, '');

        notesContent = notesContent.replace(/<div>\r<\/div>/g, '');
        notesContent = notesContent.replace(/<div><b>\r<\/b><\/div>/g, '');

        const { activeTextElement, bookContent } = this.state;
        const ctent = bookContent.sessions[this.state.sessionId].content;
        ctent[activeTextElement].value = notesContent;

        if (notesContent.search(/{([A-Za-z_0-9]*)}/) !== -1) {
          ctent[activeTextElement].type = 'inlineQuestion';
        } else {
          ctent[activeTextElement].type = 'text';
        }

        //this.setState({bookContent}, ()=>this.saveCount++);
        this.saveCount++;
      },
      actions: [
        'bold',
        'underline',
        'italic',
        'olist',
        'ulist',
        'heading1',
        //"heading2",
        {
          name: 'heading2',
          title: 'heading 2 alt+h',
          icon: '<b>H<sub>2</sub></b>',
          result: () => exec('formatBlock', '<h2>')
        },
        'paragraph',
        /*'line',*/ 'link',
        {
          name: 'indent',
          icon: '>',
          title: 'indent',
          result: () => {
            exec('indent');
          }
        },
        {
          name: 'outdent',
          icon: '<',
          title: 'outdent',
          result: () => {
            exec('outdent');
          }
        },
        {
          name: 'Center',
          icon: 'C',
          title: 'Center',
          result: () => {
            exec('justifyCenter');
          }
        },
        {
          name: 'Left',
          icon: 'L',
          title: 'Left',
          result: () => {
            exec('justifyLeft');
          }
        },
        /*
        {
          name: 'Clear formating',
          icon: 'C',
          title: 'Clear formating',
          result: () => {exec('removeFormat')}
        },
        {
          name: 'link',
          result: () => {
            const url = window.prompt('Enter the link URL')
            if (url) exec('createLink', url)
          }
        },
        */
        {
          name: 'Red font',
          icon: '<font color="red">R</font>',
          title: 'Red font',
          result: () => {
            exec('foreColor', '#FF0000');
          }
        },
        {
          name: '紫色',
          icon: '<font color="#c16766">紫</font>',
          title: '紫色',
          result: () => {
            exec('foreColor', '#c16766'); //""#605c7f");
          }
        },
        //
        {
          name: 'hiliteColor',
          icon: '<span style="background-color:yellow">Y</span>',
          title: 'hiliteColor',
          result: () => {
            exec('hiliteColor', '#FFFF00');
          }
        }
      ],
      classes: {
        content: 'PellContent',
        button: 'PellButton'
      }
    });

    //alt+h short cut to put on heading2
    this.editor.content.addEventListener('keyup', (e: any) => {
      if (e.altKey && e.keyCode == 72) {
        //} e.code === "KeyH") {
        //console.log(e);
        exec('formatBlock', '<h2>');
      }
    });
    const ctent = this.state.bookContent.sessions[this.state.sessionId].content;
    const ccc = ctent[this.state.activeTextElement];
    const txt = ccc.value as string;

    /*
    if (ccc.type === "inlineQuestion") {
      txt = txt.replace(/{([A-Za-z_0-9]*)}/g, (match)=>{
        return `<input readonly value = '${match}' style='width:10em;background:#d3d3d3;text-align:center'/>`;
      });
    }
    */

    this.editor.content.innerHTML = txt;

    this.editor.content.addEventListener('paste', (e: any) => {
      console.log('received paste event: ', e);
      // cancel paste
      e.preventDefault();

      // get text representation of clipboard
      const text = (e.originalEvent || e).clipboardData.getData('text/plain');

      // insert text manually
      exec('insertText', text);
    });
  }

  async saveEvery5Seconds(saveCount: number) {
    let newSaveCount = this.saveCount;
    if (this.saveCount > saveCount) {
      if (!isUserSignedIn()) {
        //alert("登录失效，需要重新登录");
        return;
      }
      //to inform the phone emulator
      this.props.setChangeCount(this.saveCount);

      const ooo = JSON.stringify(this.state.bookContent);
      const ccc = ooo.replace(/,"valueParsed":[^\]]*\]/g, '');
      const sss = JSON.parse(ccc);
      console.log('save the cleaned object: ', sss);

      const result = await _dataProvider.saveContent(sss, this.state.bookContent.chapterId);
      if (!result) {
        //We don't want to annoy ever 5 second if there is no connection?
        if (this.lastSaveSuccess) {
          alert('Data save failed, please check connection, will auto try saving every 5 seconds');
        }
        this.lastSaveSuccess = false;
        newSaveCount = saveCount; // failed, so reset to start over
      } else {
        this.lastSaveSuccess = true;
      }
    }
    // start the next one
    this.timeoutId = setTimeout(
      (s) => {
        this.saveEvery5Seconds(s);
      },
      3000, // reduce to every 3 seconds
      newSaveCount
    );
  }

  public shouldComponentUpdate(nextProps: IContentEditorProps, nextState: IContentEditorState) {
    console.log('ContentEditor shouldComponentUpdate');
    //must always set editor = null, as the editor could be moved up or down, or even deleted
    if (this.state.activeTextElement !== nextState.activeTextElement) {
      this.editor = null;
    }
    if (nextProps.bookContent !== this.props.bookContent) {
      this.setState(this.initState(nextProps));
    }
    if (this.props.contentHeight !== nextProps.contentHeight) {
      this.setState({
        contentHeight: `${parseInt(nextProps.contentHeight) - document!.getElementById('idHeader')!.clientHeight}px`
      });
    }

    return true;
  }
}

export default ContentEditor;
