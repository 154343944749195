import React from 'react';
//import { Link } from "react-router-dom";
import i18n from '../i18n';

interface IAboutUsProps {
  localScroll?: any;
}

const Footer = (props: IAboutUsProps) => (
  <div className='footer'>
    <ul style={{ display: 'flex', justifyContent: 'center' }}>
      <li>
        <div>
          <a
            href='./#/AboutUs#fb'
            className='FooterLink'
            onClick={() => {
              if (props.localScroll) {
                props.localScroll('#fb');
              }
            }}>
            {i18n.t('UserFeedback')}
          </a>
          |
        </div>
      </li>
      <li>
        <div>
          <a
            href='./#/AboutUs#au'
            className='FooterLink'
            onClick={() => {
              if (props.localScroll) {
                props.localScroll('#au');
              }
            }}>
            {i18n.t('AboutUs')}
          </a>
          |
        </div>
      </li>
      <li>
        <div>
          <a
            href='./#/AboutUs#tm'
            className='FooterLink'
            onClick={() => {
              if (props.localScroll) {
                props.localScroll('#tm');
              }
            }}>
            {i18n.t('Terms')}
          </a>
        </div>
      </li>
    </ul>
  </div>
);

export default Footer;
